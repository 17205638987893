<template>
  <div class="popup">
    <div class="popup__trigger" @click="open">
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      :custom-class="customClass"
      :visible.sync="visible"
      :width="width"
      :top="top"
      :center="center"
      :close-on-click-modal="closeOnClickModal"
      :modal-append-to-body="true"
      :append-to-body="true"
      @closed="close"
    >
      <div v-if="title" slot="title" style="font-size: 14px">
        {{ title }}
      </div>
      <div v-else slot="title" style="font-size: 14px">温馨提示</div>

      <slot>{{ content }}</slot>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" v-if="cancelButton" @click="onEvent('cancel')">
          {{ cancelButton }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          v-if="confirmButton"
          @click="onEvent('confirm')"
        >
          {{ confirmButton }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  provide() {
    return {
      visible: this.visible,
    };
  },
  props: {
    // 弹窗标题
    title: {
      type: String,
      default: "",
    },
    // 弹窗内容
    content: {
      type: String,
      default: "确认要删除？",
    },
    // 确认按钮
    confirmButton: {
      type: [String, Boolean],
      default: "确认",
    },
    // 取消按钮
    cancelButton: {
      type: [String, Boolean],
      default: "取消",
    },
    // 弹窗宽度
    width: {
      type: String,
      default: "400px",
    },
    // 弹窗距离顶部位置
    top: {
      type: String,
      default: "20vh",
    },
    // 是否居中布局
    center: {
      type: Boolean,
      default: false,
    },
    // 是否禁用弹窗
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否异步关闭
    async: {
      type: Boolean,
      default: false,
    },
    // 点击遮罩层关闭弹窗
    closeOnClickModal: {
      type: Boolean,
      default: false,
    },
    // 自定义样式
    customClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    open() {
      if (this.disabled) return;
      this.visible = true;
      this.$emit("open");
    },
    close() {
      this.visible = false;
      this.$nextTick(() => {
        this.$emit("close");
      });
    },
    onEvent(type) {
      this.$emit(type);
      if (!this.async || type === "cancel") {
        this.close();
      }
    },
  },
};
</script>
