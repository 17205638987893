<template>
  <popup
    ref="popupRefs"
    :async="true"
    width="600px"
    :title="identity ? '编辑授权' : '购买授权'"
    confirm-button="确认"
    @confirm="onConfirm"
    @close="onClose"
  >
    <el-form
      ref="formRefs"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="80px"
      size="small"
    >
      <el-form-item label="套餐类型" prop="combo_id" v-if="!identity">
        <el-select
          v-model="form.combo_id"
          placeholder="请选择套餐类型"
          style="width: 100%"
        >
          <el-option
            v-for="item in combo"
            :key="item.id"
            :label="`套餐名称：${item.name} | 套餐价格：${item.money} | 授权天数：${item.days}`"
            :value="item.id"
          >
            <span>套餐名称：{{ item.name }} | 套餐价格：{{ item.money }} | 授权天数：{{ item.days }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="域名或IP" prop="domain_ip">
        <el-input v-model="form.domain_ip" placeholder="请输入域名或者IP"></el-input>
        <p>（请输入域名或者IP，例如：www.example.com 或 192.168.1.1）</p>
      </el-form-item>
    </el-form>
  </popup>
</template>

<script>
import Popup from "@/components/popup/index.vue";

import {apiListCombo} from "@/api/combo";
import {apiAddAuthorize, apiDetailAuthorize, apiEditAuthorize} from "@/api/authorize";

export default {
  components: {
    Popup,
  },
  data() {
    return {
      identity: null,
      loading: false,
      combo: [],
      form: {
        combo_id: "",
        domain_ip: "",
      },
      rules: {
        combo_id: [
          {
            required: true,
            message: "请选择套餐类型",
            trigger: ["blur"],
          },
        ],
        domain_ip: [
          {
            required: true,
            message: "请输入域名或者IP",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    getDetail() {
      this.loading = true;
      apiDetailAuthorize({id: this.identity}).then((resp) => {
        Object.keys(resp).map((item) => {
          this.$set(this.form, item, resp[item]);
          this.loading = false;
        });
      });
    },
    getList() {
      apiListCombo({}).then((resp) => {
        this.combo = resp.list;
      });
    },
    openDialog(id) {
      this.getList();
      this.identity = id;
      if (this.identity) {
        this.getDetail();
      }
      this.$refs.popupRefs.open();
    },
    closeDialog() {
      this.$refs.popupRefs.close();
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs.formRefs.clearValidate();
        this.$refs.formRefs.resetFields();
      });
    },
    onConfirm() {
      this.$refs.formRefs.validate((valid) => {
        if (!valid) return;
        const api = this.identity
          ? apiEditAuthorize(this.form)
          : apiAddAuthorize(this.form);
        api.then(() => {
          this.closeDialog();
          this.$message({
            type: "success",
            message: "操作成功！",
          });
          this.$emit("refresh");
        });
      });
    },
  },
};
</script>
