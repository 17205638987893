import request from "@/utils/request";

/**
 * 获取授权列表
 * @param {*} params
 * @returns
 */
export function apiListAuthorize(params) {
  return request.get("/authorize/list", params);
}

/**
 * 获取授权详情
 * @param {*} params
 * @returns
 */
export function apiDetailAuthorize(params) {
  return request.get("/authorize/detail", params);
}

/**
 * 添加授权
 * @param {*} params
 * @returns
 */
export function apiAddAuthorize(params) {
  return request.post("/authorize/add", params);
}

/**
 * 编辑授权
 * @param {*} params
 * @returns
 */
export function apiEditAuthorize(params) {
  return request.post("/authorize/edit", params);
}


export function apiStatusAuthorize(params) {
  return request.post("/authorize/status", params);
}
