<template>
  <div class="app-container">
    <el-card shadow="never">
      <div class="-mb-16px">
        <el-form
          ref="searchRefs"
          :model="params"
          size="small"
          label-width="85px"
          inline
        >
          <el-form-item label="域名/IP" prop="domain_ip">
            <el-input v-model="params.domain_ip" placeholder="请输入域名或IP"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="params.status" placeholder="请选择状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="自动续费" value="1"></el-option>
              <el-option label="到期删除" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml-6px">
            <el-button type="primary" @click="getList(1)">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="mt-16px" v-loading="pager.loading" shadow="never">
      <div>
        <el-button type="primary" size="small" @click="onNew">
          购买授权
        </el-button>
      </div>
      <div class="mt-16px">
        <el-table :data="pager.list">
          <el-table-column
            label="授权ID"
            prop="id"
            width="80"
          ></el-table-column>
          <el-table-column
            label="套餐名称"
            width="200"
            prop="combo_name"
          ></el-table-column>
          <el-table-column
            label="授权域名 / IP"
            prop="domain_ip"
          ></el-table-column>
          <el-table-column label="创建时间" prop="login_time" width="160">
            <template v-slot="scope">
              <span>{{ formatDate(scope.row.create_time, 'yyyy-mm-dd hh:MM:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="到期时间" prop="login_time" width="160">
            <template v-slot="scope">
              <span>{{ formatDate(scope.row.expiry_time, 'yyyy-mm-dd hh:MM:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template v-slot="scope">
              <popup
                v-if="scope.row.status === 1"
                :content="`确定将状态修改成到期删除？请谨慎操作。`"
                @confirm="onStatus(scope.row, 2)"
              >
                <el-button slot="trigger" type="text">
                  <el-tag style="cursor: pointer;" size="small">自动续费</el-tag>
                </el-button>
              </popup>
              <popup
                v-if="scope.row.status === 2"
                :content="`确定将状态修改成自动续费？请谨慎操作。`"
                @confirm="onStatus(scope.row, 1)"
              >
                <el-button slot="trigger" type="text">
                  <el-tag style="cursor: pointer;" size="small" type="danger">到期删除</el-tag>
                </el-button>
              </popup>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <div class="flex items-center">
                <el-button type="text" @click="onEdit(scope.row.id)">
                  编辑
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-between mt-16px">
        <div class="flex"></div>
        <pagination
          v-model="pager"
          :pageSizes="[10, 20, 30]"
          @change="getList()"
        ></pagination>
      </div>
    </el-card>

    <edit-dialog ref="popupRefs" @refresh="getList"></edit-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import Popup from "@/components/popup";
import EditDialog from "./dialog.vue";
import {Pager} from "@/utils/pager";

import {apiListAuthorize, apiStatusAuthorize} from "@/api/authorize";
import {formatDate} from "@/utils/util";

export default {
  components: {
    EditDialog,
    Pagination,
    Popup,
  },
  data() {
    return {
      params: {
        domain_ip: "",
        status: "",
      },
      pager: new Pager(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    formatDate,
    onReset() {
      this.$refs.searchRefs.resetFields();
      this.getList(1);
    },
    getList(page) {
      page && (this.pager.page = page);
      this.pager.request({
        callback: apiListAuthorize,
        params: {
          ...this.params,
        },
      });
    },
    onNew() {
      this.$refs.popupRefs.openDialog();
    },
    onEdit(id) {
      this.$refs.popupRefs.openDialog(id);
    },
    onStatus(row, status) {
      apiStatusAuthorize({
        id: row.id,
        status: status,
      }).then(() => {
        this.$message({
          type: "success",
          message: "状态修改成功！",
        });
        this.getList();
      });
    }
  },
};
</script>
