import request from "@/utils/request";

/**
 * 获取套餐列表
 * @param {*} params
 * @returns
 */
export function apiListCombo(params) {
  return request.get("/combo/list", params);
}
